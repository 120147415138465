.App {
  position: relative;
  background-color: black;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
