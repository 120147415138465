.slide {
  position: absolute;
  top:0;
  height:100%;
  width:100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.current {
  opacity: 1;
  animation: fadeIn 1s;
  z-index: -1;
}

.prev {
  opacity: 0;
  animation: fadeOut 1s;
  z-index: -2;
}

.video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}
